export const MIN_PASSWORD_LENGTH = 8;
export const BLS_CATEGORY = {
  Housing: 'housing',
  Utilities: 'utilities',
  Transportation: 'transportation',
  Healthcare: 'healthcare',
  'Food & Household Supplies': 'food_household',
  'Clothing & Personal': 'clothing_personal',
  Entertainment: 'entertainment',
  'Cash Spending': 'cash_spending',
  'Miscellaneous and Other Debts': 'misc',
  Education: 'education',
};

export const INCOME = 'income';
export const EXPENSE = 'expense';

// ========================================================
// Profile form constants.
// These represent the values as stored on the backend.
// ========================================================

// Living Situation constants.
export const HOME_MORTGAGE = 'home_mortgage';
export const HOME_NO_MORTGAGE = 'home_no_mortgage';
export const RENT = 'rent';
export const OTHER = 'other';

// Marital status constants.
export const SINGLE = 'single';
export const MARRIED = 'married';
export const DIVORCED = 'divorced';
export const SEPARATED = 'separated';
export const WIDOWED = 'widowed';

// Gender constants.
export const MALE = 'male';
export const FEMALE = 'female';
export const NON_BINARY = 'nonBinary';

// Age range constants.
// Keys are labels, values are values.
export const AGE_RANGE_OPTIONS = {
  '<25': 'under_25',
  '25-35': '26_to_35',
  '36-45': '36_to_45',
  '46-55': '46_to_55',
  '56-65': '56_to_65',
  '>65': 'over_65',
};

// Income constants.
// Keys are labels, values are values.
export const INCOME_OPTIONS = {
  '<$25,000': 'less_than_25',
  '$25,001-$35,000': '25_to_35',
  '$35,001-$50,000': '35_to_50',
  '$50,001-$75,000': '50_to_75',
  '$75,001-$100,000': '75_to_100',
  '$100,001-$250,000': '100_to_250',
  '$250,000+': 'over_250',
};

// This comes from AngularJS, which is itself derived from the regex used in Chromium.
export const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

// Learn page constants.
export const PAGE_NUMBER = 1;
export const ARTICLES_LIMIT = 10;

export const JOURNEY_NAMES = {
  HomeBuying: 'Homebuying Journey',
  HomeRefinance: 'Your Journey to a Home Refinance',
  Savings: 'Build Your Best Savings Journey',
  CarBuying: 'Buying and Insuring a Car Journey',
  CreditCard: 'Choose the Right Credit Card',
};

// navbar brand orientation
export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';
// Constants for Equifax SMFA API results.
export const SMFA_RESULT = {
  GREEN: 'Green',
  YELLOW: 'Yellow',
  ORANGE: 'Orange',
  RED: 'Red',
  SUCCESS: 'SUCCESS',
};